import { apiToastWarning } from '@/@core/utils/toast'
import { kpEndpoint } from '@/axios'
import { FILE_FORMATS } from '@/constants'
import store from '@/store'
import { DOWNLOAD_FILE } from '@/store/modules/common.module'

export const goToPreapprovedSignupPage = () => {
  if (process.env.VUE_APP_FRONTEND_URL) {
    const url = `${process.env.VUE_APP_FRONTEND_URL}/register/choose-role?isAdmin=true`
    window.open(url, '_blank')
  }
}

export const exportUser = role => {
  try {
    const payload = {
      url: kpEndpoint.user.export,
      fileFormat: FILE_FORMATS.CSV,
      fileName: `${role}-users.csv`,
      params: {
        role,
      },
    }
    store.dispatch(DOWNLOAD_FILE, payload)
  }
  catch (err) {
    apiToastWarning(err)
  }
}
